import React, {useState, useEffect} from 'react'
import styled from 'styled-components'

export default function PositionsFilter(props) {

    const [positionTypes, setPositionTypes] = useState([])

    const { 
        handlePositionTypeFilter,
        positionType,
        listings 
    } = props

    //Find Unique Positions
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    useEffect(() => {
        const positions = listings.map(item => item.position)
        setPositionTypes(positions.filter(onlyUnique))
    },[])

    return (
        <Select 
            onChange={handlePositionTypeFilter}
            // value={positionType}    
        >
            <option value=''>All Positions</option>
            <option value='Full-time'>Full-time</option>
            <option value='Part-time'>Part-time</option>
        </Select>
    )
}

const Select = styled.select`
    grid-column: 3 / span 2;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    font-size: 1.6rem;
`