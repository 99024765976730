import React, { useState, useEffect } from 'react'
// import {db} from '../firebase'
import styled from 'styled-components'
import FilterBox from './FilterBox'
import Hero from './Hero'
import ListingCard from './ListingCard'
import {Route} from 'react-router-dom'
import Listing from './Listing'

export default function Listings(props) {

    const {user, listings, favoriteIds} = props

    // const [listings, setListings] = useState([]);
    const [searchResults, setSearchResults] = useState([])
    const [search, setSearch] = useState('')
    const [positionType, setPositionType] = useState('')
    const [provinceFilter, setProvinceFilter] = useState('')
    const [provinceLongFilter, setProvinceLongFilter] = useState('')
  
    const handleSearchFilter = e => {
        setSearch(e.target.value)
    }

    const handlePositionTypeFilter = e => {
        setPositionType(e.target.value)
    }

    const handleProvinceFilter = e => {
        setProvinceFilter(e.target.value)
    }

    const provinceCreator = (province) => {
        switch(province) {
            case ('AB'):
                return 'Alberta'
            case ('BC'):
                return 'British Columbia'
            case ('MB'):
                return 'Manitoba'
            case ('NB'):
                return 'New Brunswick'
            case ('NL'):
                return 'Newfoundland'
            case ('NS'):
                return 'Nova Scotia'
            case ('ON'):
                return 'Ontario'
            case ('PE'):
                return 'Prince Edward Island'
            case ('QC'):
                return 'Quebec'
            case ('SK'):
                return 'Saskatchewan'
            case ('NWT'):
                return 'North West Territories'
            case ('YT'):
                return 'Yukon'
            default:
                return ''
        }
    }

    useEffect(() => {
        setProvinceLongFilter(provinceCreator(provinceFilter))
    },[provinceFilter])

    // useEffect(() => {
    //     console.log(provinceLongFilter, provinceFilter)
    // },[provinceLongFilter])


    //On change of search filter listings
    useEffect(() => {

        const results = listings
            .filter(listing => (listing.description.toLowerCase().includes(search.toLowerCase())))
            .filter(listing => (listing.address.includes(provinceFilter || provinceLongFilter) || listing.location.includes(provinceFilter || provinceLongFilter) || listing.description.includes(provinceLongFilter)))
            .filter(listing => (listing.position.includes(positionType)))
            .filter(listing => (listing.status !== 'expired'))

        setSearchResults([...results.reverse()])
           
        if(favoriteIds !== null){
            favoriteIds.forEach(item => {
                let updateItem = results.findIndex(listing => listing.id === item.listing)
                results[updateItem] = {...results[updateItem], favId: item.id}
            })    
    
            setSearchResults(results)
        }

    },[search,listings,positionType,provinceFilter,provinceLongFilter, favoriteIds, user])

    // useEffect(() => {
    //     if(favoriteIds !== null){
    //         favoriteIds.forEach(item => {
    //             let updateItem = searchResults.find(listing => listing.id === item.listing)
    //             updateItem = {...updateItem, favId: item.id}
    //         })
    // }
    // }, [favoriteIds])


    const description = "Job Opportunities for Canadian Dentists"


    // searchResults[0] = {...searchResults[0], favId: 'test'}

    // console.log(searchResults)


    return (
        <>
        <Route exact path='/' render={(props) =>(
            <>
                <Hero title='Daily Dentist' description={description}/>
                <FilterBox
                    listings={searchResults}
                    handleSearchFilter={handleSearchFilter}
                    search={search}
                    setSearch={setSearch}
                    handlePositionTypeFilter = {handlePositionTypeFilter}
                    positionType={positionType}
                    handleProvinceFilter = {handleProvinceFilter}
                    provinceFilter = {provinceFilter}
                />
                <ListingsWrapper>
                <Summary>Results: {searchResults.length} listings</Summary>
                    {searchResults.map((listing) => {
                        return(
                            <ListingCard key={listing.identifier} listing={listing} user={user} favoriteIds={favoriteIds} />
                        )
                    })}
                </ListingsWrapper>
            </>
        )} />

        <Route path='/listings/:id' render={(props) => (
            <Listing listings={listings} favoriteIds={favoriteIds} user={user}/>
        )} />
        </>
    )
}

const ListingsWrapper = styled.div`
display: grid;
grid-template-columns: minmax(350px,950px);
gap: 2rem;
justify-content: center;
padding: 2rem;
padding-bottom: 3rem;
`

const Summary = styled.h4`
    font-size: 1.4rem;
    color: #fff;
`

export {ListingsWrapper}