import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBz09hx6kdEycmCmBplADmLsIU3r0ptih0",
    authDomain: "dailydentist-5743a.firebaseapp.com",
    databaseURL: "https://dailydentist-5743a.firebaseio.com",
    projectId: "dailydentist-5743a",
    storageBucket: "dailydentist-5743a.appspot.com",
    messagingSenderId: "1041345871063",
    appId: "1:1041345871063:web:363cb3610d2e7c64364492",
    measurementId: "G-NCC1F2VG8V"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore();
const rtDb = firebaseApp.database();
const auth = firebase.auth();
const storage = firebase.storage();

export {db, rtDb, auth, storage};