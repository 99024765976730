import React from 'react'
import styled from 'styled-components'

export default function Search(props) {

    return (
        <Input 
            type='text' 
            placeholder='Search' 
            onChange={props.handleSearchFilter}    
            value={props.search}
    />
    )
}

const Input = styled.input`
    grid-column: 1 / span 4;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    font-size: 1.6rem;
`