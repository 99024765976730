import React from 'react'
import styled from 'styled-components'
import {rtDb} from '../firebase'
import { useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'

export default function ListingCard(props) {

    const listing = props.listing
    const user = props.user
    const history = useHistory();


    const addToOnClick = () => {
        rtDb.ref(`users/${user.uid}/favorites`).push(listing.id)
    }

    const removeOnClick = () => {
        rtDb.ref(`users/${user.uid}/favorites/${listing.favId}`).remove()
    }


    function MyListButtons() {
        if(user !== null && props.favoriteIds !== null){

            if(props.favoriteIds.map(item => item.listing)
            .includes(listing.id)) {
                return (
                    // <AddToButton onClick={removeOnClick}>Remove from My List</AddToButton>
                    <AddDiv>
                        <IconButton aria-label="Remove from My list" onClick={removeOnClick}>
                            <Tooltip title='Remove from My List'>
                                    <FavoriteIcon fontSize='large' color='primary' />
                            </Tooltip>  
                        </IconButton>
                    </AddDiv>
                )
            } else {
                return (

                    // <AddToButton onClick={addToOnClick}>Add to My List</AddToButton>
                    <AddDiv>
                        <IconButton aria-label="add to my list" onClick={addToOnClick}>
                            <Tooltip title='Add to My List'>
                                    <FavoriteBorderIcon fontSize='large' />
                            </Tooltip>  
                        </IconButton>
                    </AddDiv>
                )
            }

        } else {
            return (
                <AddDiv>
                    <IconButton aria-label="add to my list" onClick={() => history.push('/sign-up')}>
                        <Tooltip title='Add to My List'>
                                <FavoriteBorderIcon fontSize='large' />
                        </Tooltip>  
                    </IconButton>
                </AddDiv>
            )
        }
    }


    const newJobs = () => {
        if(props.listing.status === 'new'){
            return ` - ${props.listing.status}`
        }
    }

    return (
        <CardBox>
            <CardHeader>
                <HeaderContent>
                    <h2>{listing.location} {newJobs()}</h2>
                    <Subhead>{listing.position}</Subhead>
                </HeaderContent>
                { MyListButtons() }
            </CardHeader>
            <Description>{listing.description.substring(0,300)}...</Description>
            {/* <GoToLink to={`/listings/${listing.id}`}>View Listing</GoToLink> */}
            <Button variant="contained" elevation={2} color="primary" href={`/listings/${listing.id}`}>
                View Listing
            </Button>
        </CardBox>
    )
}

const CardBox = styled.div`
    border-radius: .5rem;
    box-shadow: rgba(42, 51, 83, 0.12) 0px 15px 35px 0px, rgba(0, 0, 0, 0.06) 0px 5px 15px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: start;
    align-content: start;
    font-size: 1.6rem;
    width: 100%;
    gap:0;
    background-color: #fff;
    padding: 2rem;
    z-index: 1;
`

const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    width: 100%;
`

const HeaderContent = styled.div`
    // display: flex;
    // flex-wrap: wrap;
    // align-items: baseline;
    // align-content: center;
    // gap: 1rem;
`

const Subhead = styled.p`
    color: #ccc;
`

const Description = styled.p`
    padding: 1rem 0 2rem;

`

// const Button = styled.a`
//     padding: .5rem 1rem;
//     font-size: 1.6rem;
//     border-radius: 6px;
//     color: #fff;
//     font-weight: 700;
//     background: #882AE1;
//     cursor: pointer;
//     margin-top: 1rem;
//     text-decoration: none;
//     &:hover{
//         background-color: #6712E0;
//     }
// `

// const GoToLink = styled(Link)`
//     text-decoration: none;
//     color: inherit;
// `

// const AddToButton = styled.button`
//     justify-self: end;
//     align-self: start;
//     padding: .5rem 1rem;
//     font-size: 1.4rem;
//     border-radius: 6px;
//     color: #fff;
//     font-weight: 500;
//     background: #1B72E7;
//     cursor: pointer;
//     // margin-bottom:-3rem;
//     text-decoration: none;
//     z-index: 999;
//     &:hover{
//         background-color: #1976D1;
//     }
// `
const AddDiv = styled.div`
    display: grid;
    height: 100%;
    justify-self: end;
    align-self: start;
    align-content: center;
    justify-content: center;
    z-index: 999;
`