import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import {useHistory} from 'react-router-dom'

export default function Nav(props) {

    const history = useHistory()

    return (
        <NavBox>
            <Logo to='/'>DailyDentist.ca</Logo>
            <Menu>
                <MenuLinks to='/'>Listings</MenuLinks>
                {props.user === null 
                    ? 
                        <>
                            <MenuLinks to='/log-in'>Log In</MenuLinks>
                            <MenuLinks to='/sign-up'>Sign Up</MenuLinks>
                        </>
                    : 
                        <>
                        <MenuLinks to='/my-favourites' >My List</MenuLinks>
                        <Button onClick={() => auth.signOut().then(() => history.push('/'))}>Sign Out</Button>
                        </>

                }
            </Menu>
        </NavBox>
    )
}

const NavBox = styled.nav`
    display: grid;
    min-height: 50px;
    max-width: 2048px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-content: center;
    align-items: center;
    padding: 0 3rem;
    color: #fff;
    text-shadow: 2px 3px 3px rgba(0,0,0,0.3);
    @media (max-width: 650px) {
        text-align: center;
    }

`


const Logo = styled(Link)`
    font-size: 2.4rem;
    text-decoration: none;
    color: inherit;
    font-weight: 800;
    padding: 1rem;
    &:hover{
        text-shadow: 2px 3px 3px rgba(0,0,0,0.7);
    }
    @media (max-width: 650px){
        display: none;
    }

`

const Menu = styled.div`
    justify-self: end;
    @media (max-width: 650px){
        justify-self: center;
        padding-bottom: 1rem;
    }
`

const MenuLinks = styled(Link)`
    padding: 0 1.5rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    &:hover{
        text-shadow: 2px 3px 3px rgba(0,0,0,0.7);
    }
`

const Button = styled.button`
    padding: .35rem 1rem;
    box-shadow: 2px 3px 3px rgba(0,0,0,0.3);
    margin-left: 1.5rem;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    background-color: #fff;
    &:hover{
        box-shadow: 2px 3px 3px rgba(0,0,0,0.7);
        background-color: #fbfbfb;
    }
`