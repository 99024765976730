import React from 'react'
import ListingCard from './ListingCard'
import Hero from './Hero'
import styled from 'styled-components'

export default function MyFavorites(props) {

const {user, favoritesList, favoriteIds} = props

const title = "My List"
const des = "Save Your Favourite Jobs Here"

return(
        <>
        <Hero title={title} description={des} />
        <ListingsWrapper>
            {
                favoritesList === null
                ? <p>loading</p>
                : <>
                {favoritesList.map((listing) => {
                    return(
                        <ListingCard key={listing.identifier} listing={listing} user={user} favoriteIds={favoriteIds} />
                    )
                })}
                </>
            }
        </ListingsWrapper>
        </>
)

}

const ListingsWrapper = styled.div`
display: grid;
grid-template-columns: minmax(350px,950px);
gap: 2rem;
justify-content: center;
padding: 2rem;
padding-bottom: 3rem;
`
