import React, {useState, useEffect} from 'react'
import Nav from './components/Nav';
import Listings from './components/Listings'
import styled from 'styled-components'
import { rtDb, auth } from './firebase'
import SignInForm from './components/SignInForm'
import SignUpForm from './components/SignUpForm'
import {Route} from 'react-router-dom'
import MyFavorites from './components/MyFavorites';


function App() {

  const [user, setUser] = useState(null)
  const [listings, setListings] = useState([])

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
        if (authUser){
            //user has logged in
            setUser(authUser);

        } else {
            //user has logged out
            setUser(null)
        }
    })
  },[])

  //Get Listings
  useEffect(() => {
    const postsRef = rtDb.ref('listings');
    postsRef.on('value', (snapshot) => {
    const posts = snapshot.val();
    const postList = []
    for (let id in posts){
        postList.push({id, ...posts[id]})
    }
    setListings(postList)
    })
  },[user])


//Favorites List
const [favoriteIds, setFavoriteIds] = useState(null)
const [favoritesList, setFavoritesList] = useState(null)

useEffect(() => {
    if(user !== null){
        const postsRef = rtDb.ref(`users/${user.uid}/favorites/`);
        postsRef.on('value', (snapshot) => {
          const posts = snapshot.val();
          const favIds = []
            for (let id in posts){
                favIds.push({id: id, listing: posts[id]})
            }
            setFavoriteIds(favIds)
        })
        
    } 
    
},[user])




useEffect(() => {
    if(favoriteIds !== null){

        const favListings = favoriteIds.map(id => { 
          const listingItem = listings.find(listing => listing.id === id.listing)
          return {favId: id.id, ...listingItem}
        })
        setFavoritesList(favListings)

    }
    
},[favoriteIds, listings])
  

  return (
      <PageWrapper>
      <Nav user={user} />
      <Listings user={user} listings={listings} favoritesList={favoritesList} favoriteIds={favoriteIds}/>
      <Route path='/sign-up' render={(props) => (<SignUpForm user={user} />)} />
      <Route path='/log-in' render={(props) => (<SignInForm />)} />
      <Route path='/my-favourites' render={(props) => (<MyFavorites user={user} listings={listings} favoritesList={favoritesList} favoriteIds={favoriteIds} />)} />
      </PageWrapper>

  );
}

export default App;

const PageWrapper = styled.div`
  min-height: 100vh;
  background: #654ea3;  /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #eaafc8, #654ea3);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #eaafc8, #654ea3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
+, Opera 12+, Safari 7+ */
`