import React from 'react'
import styled from 'styled-components'

export default function LocationFilter() {
    return (
        <Select 
            // onChange={props.handleCategoryFilter}
            // value={props.category}    
        >
            <option value=''>All Provinces</option>
            <option value='Alberta'>Alberta</option>
            <option value='British Columbia'>British Columbia</option>
            <option value='Manitoba'>Manitoba</option>
            <option value='New Brunswick'>New Brunswick</option>
            <option value='Newfoundland'>Newfoundland</option>
            <option value='Nova Scotia'>Nova Scotia</option>
            <option value='Ontario'>Ontario</option>
            <option value='Prince Edward Island'>Prince Edward Island</option>
            <option value='Quebec'>Quebec</option>
            <option value='Saskatchewan'>Saskatchewan</option>
        </Select>
    )
}

const Select = styled.select`
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: .5rem 0;
    width: 100%;
`