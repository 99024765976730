import React from 'react'
import styled from 'styled-components'

export default function Hero(props) {

    return (
        <HeroWrapper>
        <Headline>{props.title}</Headline>
        <SubHead>{props.description}</SubHead>
    </HeroWrapper>
    )
}

const HeroWrapper = styled.div`
    display: grid;
    min-height: 25rem;
    grid-template-columns: minmax(350px, 650px);
    justify-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding: 2rem;
    color: #fff;
    text-shadow: 2px 3px 3px rgba(0,0,0,0.3);
`

const Headline = styled.h1`
    font-size: 4.5rem;
    letter-spacing: -.2rem;
`
const SubHead = styled.h4`
    font-size: 1.8rem;
    font-weight: 500;
`