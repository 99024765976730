import React, {useState, useEffect} from 'react'
import { useRouteMatch, Link, useHistory } from 'react-router-dom'
import {rtDb} from '../firebase'
import styled from 'styled-components'
import Map from './Map'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';

export default function Listing(props) {

    const routeMatch = useRouteMatch();
    let id = routeMatch.params.id
    const history = useHistory()

    const {user} = props

    const [listingInfo, setListingInfo] = useState(null)

    useEffect(() => {
        setListingInfo(props.listings.find((listing) => listing.id === id))
    },[id,props.listings])


    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
      }

    function formatWebAddress(webString) {
        if(webString.substr(0,4) !== 'http'){
            return('http://' + webString)
        } else {
            return webString
        }
    }

    const addToOnClick = () => {
        rtDb.ref(`users/${user.uid}/favorites`).push(listingInfo.id)
    }

    const removeOnClick = () => {
        rtDb.ref(`users/${user.uid}/favorites/${listingInfo.favId}`).remove()
    }

    function MyListButtons() {
        if(user !== null && props.favoriteIds !== null){

            if(props.favoriteIds.map(item => item.listingInfo)
            .includes(listingInfo.id)) {
                return (
                    // <AddToButton onClick={removeOnClick}>Remove from My List</AddToButton>
                    <AddDiv>
                        <IconButton aria-label="Remove from My list" onClick={removeOnClick}>
                            <Tooltip title='Remove from My List'>
                                    <FavoriteIcon fontSize='large' color='primary' />
                            </Tooltip>  
                        </IconButton>
                    </AddDiv>
                )
            } else {
                return (

                    // <AddToButton onClick={addToOnClick}>Add to My List</AddToButton>
                    <AddDiv>
                        <IconButton aria-label="add to my list" onClick={addToOnClick}>
                            <Tooltip title='Add to My List'>
                                    <FavoriteBorderIcon fontSize='large' />
                            </Tooltip>  
                        </IconButton>
                    </AddDiv>
                )
            }

        } else {
            return (
                <AddDiv>
                    <IconButton aria-label="add to my list" onClick={() => history.push('/sign-up')}>
                        <Tooltip title='Add to My List'>
                                <FavoriteBorderIcon fontSize='large' />
                        </Tooltip>  
                    </IconButton>
                </AddDiv>
            )
        }
    }

    const newJobs = () => {
        if(listingInfo.status === 'new'){
            return ` - ${listingInfo.status}`
        }
    }

    if (!listingInfo) {
        return (
            <ListingWrapper>
                <ListingContent>
                    Loading...
                </ListingContent>
            </ListingWrapper>
        )
    }

    return (
        
        <ListingWrapper>
            <Back onClick={() => history.goBack()}> ⬅ Back to Listings</Back>
            <ListingContent>
                <CardHeader>
                    <HeaderContent>
                        <h2>{listingInfo.location} {newJobs()}</h2>
                        <p>{listingInfo.position}</p>
                    </HeaderContent>
                    { MyListButtons() }
                </CardHeader>
                <Description>{listingInfo.description}</Description>
                <ContactHeadline>Contact:</ContactHeadline>
                <Contact>
                {listingInfo.email === ''
                    ? ''
                    : 
                        <Button variant="contained" size='large'
                            href={`mailto:${listingInfo.email}`} 
                            target="_blank"
                        >
                                <i className="fa fa-envelope" aria-hidden="true"></i> 
                                {listingInfo.email}
                        </Button>
                }


                {listingInfo.phone === ''
                    ? ''
                    : 
                        <Button variant="contained" size='large'
                            href={`tel:${listingInfo.phone}`} 
                            target="_blank"
                        >
                                <i className="fa fa-phone" aria-hidden="true"></i> 
                                {formatPhoneNumber(listingInfo.phone)}
                        </Button>
                }
                
                {listingInfo.website === ''
                    ? ''
                    : 
                        <Button variant="contained" size='large'
                            href={formatWebAddress(listingInfo.website)} 
                            target="_blank"
                        >
                                <i className="fas fa-globe" aria-hidden="true"></i> 
                                Website
                        </Button>
                }

            </Contact>
                <Map address={listingInfo.address} location={listingInfo.location} />
            </ListingContent>
        </ListingWrapper>
    )
}

const ListingWrapper = styled.div`
display: grid;
grid-template-columns: minmax(250px,950px);
gap: 2rem;
justify-content: center;
padding: .5rem;
align-items: start;
font-size: 1.6rem;
`

const ListingContent = styled.div`
    box-shadow: rgba(42, 51, 83, 0.12) 0px 15px 35px 0px, rgba(0, 0, 0, 0.06) 0px 5px 15px;
    padding: 3rem 2rem;
    border-radius: .5rem;
    background-color: #fff;
`

const Contact = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    margin-bottom: 2rem;
`

const ContactHeadline = styled.h3`
    padding-bottom: 1rem;
`

const Back = styled.button`
    text-decoration: none;
    color: inherit;
    justify-self: start;
    cursor: pointer;
    font-size: 1.4rem;
    padding: .35rem 1rem;
    box-shadow: 2px 3px 3px rgba(0,0,0,0.3);
    font-weight: 600;
    border-radius: 3px;
    background-color: #fff;
    &:hover{
        box-shadow: 2px 3px 3px rgba(0,0,0,0.7);
        background-color: #fbfbfb;
    }
`

const Description = styled.p`
    border-top: .1rem solid #ccc;
    padding: 2rem 0;
    margin-top: 1rem;
    font-size: 1.6rem;

`
// const Button = styled.a`
//     padding: .5rem 1rem;
//     font-size: 1.6rem;
//     border-radius: 6px;
//     color: #fff;
//     font-weight: 700;
//     background: #882AE1;
//     cursor: pointer;
//     margin-top: 1rem;
//     text-decoration: none;
//     &:hover{
//         background-color: #6712E0;
//     }
//     @media (max-width: 320px) {
//         width: 100%;
//         margin-top: 0.5rem;
//       }
// `

const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    width: 100%;
`

const HeaderContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    align-content: center;
    gap: 1rem;
`

const AddDiv = styled.div`
    display: grid;
    height: 100%;
    justify-self: end;
    align-self: start;
    align-content: center;
    justify-content: center;
    z-index: 999;
`