import React from 'react'
import styled from 'styled-components'
import LocationFilter from './LocationFilter'
import PositionsFilter from './PositionsFilter'
import ProvinceFilter from './ProvinceFilter'
import Search from './Search'

export default function FilterBox(props) {

    const { 
        handleSearchFilter, 
        search, 
        setSearch,
        handlePositionTypeFilter,
        positionType,
        listings,
        handleProvinceFilter,
        provinceFilter 
    } = props


    return (
        <FilterArea>
            <FilterWrapper>
                <Search
                    handleSearchFilter={handleSearchFilter}
                    search={search}
                    setSearch={setSearch}
                />
                <ProvinceFilter 
                    handleProvinceFilter={handleProvinceFilter}
                    provinceFilter={provinceFilter}
                    />
                <PositionsFilter 
                    handlePositionTypeFilter={handlePositionTypeFilter}
                    positionType={positionType}
                    listings={listings}
                />
                {/* <Tags>
                <Tag><input type="checkbox" id="exceptional" name="exceptional" />Exceptional Earning</Tag>
                </Tags> */}

            </FilterWrapper>
        </FilterArea>
    )
}

const FilterArea = styled.div`
    display: grid;
    justify-content: center;
    padding: 2rem;
`

const FilterWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-area:
    max-width: 960px;
    gap: 1rem;
    padding: 0 2rem;
`

const Tags = styled.div`
    display: flex;
`

const Tag = styled.label`
    box-shadow: 2px 3px 3px rgba(0,0,0,0.3);
    background: #fff;
`