import React, {useState, useEffect} from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import mapStyles from './mapStyles'

export default function Map(props) {

    // const address = props.address.replace(/[, ]+/g, "+")

    const address = props.address.replace('Address:','').trim()

    const mapLocation = () => {
      if(address === '' || !address.includes(' ') || address.includes(':')) {
        return props.location.replace(/[, ]+/g, "+")
      } else {
        return address.replace(/[, ]+/g, "+")
      }
    }


    return (
      <iframe
        width="100%"
        height="450"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBz09hx6kdEycmCmBplADmLsIU3r0ptih0&q=${mapLocation()}+canada&zoom=8`} allowFullScreen>
      </iframe>
    )
}