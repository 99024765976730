import React from 'react'
import styled from 'styled-components'


export default function ProvinceFilter(props) {

    return (
        <Select
            onChange={props.handleProvinceFilter}
            value={props.provinceFilter}
        >
            <option value=''>All Provinces</option>
            <option value='AB'>Alberta</option>
            <option value='BC'>British Columbia</option>
            <option value='MB'>Manitoba</option>
            <option value='NB'>New Brunswick</option>
            <option value='NL'>Newfoundland</option>
            <option value='NWT'>North West Territories</option>
            <option value='NS'>Nova Scotia</option>
            <option value='ON'>Ontario</option>
            <option value='PE'>Prince Edward Island</option>
            <option value='QC'>Quebec</option>
            <option value='SK'>Saskatchewan</option>
            <option value='YT'>Yukon</option>
        </Select>
    )
}

const Select = styled.select`
    grid-column: 1 / span 2;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    font-size: 1.6rem;
`